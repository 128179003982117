import { PureComponent, Children, createElement } from 'react';

// Should be a pure class https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33006
const proxyComponentFactory = (key) => class ProxyComponent extends PureComponent {
    static displayName = key;
    render() {
        return this.props.children;
    }
};

const hasProperty = (obj, propName) => Boolean((typeof obj === "object" || typeof obj === "function") && Object.prototype.hasOwnProperty.call(obj, propName));
const isNullValueKey = (obj) => (k) => obj[k] === null;
const isKeyOf = (obj) => (key) => hasProperty(obj, key);
const typedKeys = (childrenSpec) => Object.keys(childrenSpec).filter(isKeyOf(childrenSpec));

const uncapitalize = (text) => 
// Currently TS does not allow to properly validate type as Capitalize<S>,
// we can add
// const isFirstCapital = <S extends string>(text: string): text is Capitalize<S> =>
// text.charAt(0).toUpperCase() === text.charAt(0);
// but this would force us to return unnecessary `undefined` like
// return isFirstCapital(...) ? ... : undefined;
`${text.charAt(0).toLowerCase()}${text.slice(1)}`;
/**
 * Finds matching children using @param componentMatcher and traverse them
 * using @param traverseChildren if provided.
 * NOTE: This method mutates the source array and removes all matched elements.
 * @param children array of children to extract matching components.
 * @param componentMatcher a matcher function which. SHould return `true` to confirm match.
 * @param traverseChildren a function to traverse children of matched components. Use for Proxy
 * components to skip them and use their children only
 * @returns array of matched elements. For traversed elements each element of returned array will
 * contain children of a single Proxy Component.
 */
const spliceChildrenByType = (children, key, type, componentMatcher, traverseChildren) => {
    const located = [];
    let componentPos = -1;
    while ((componentPos = children.findIndex((c) => componentMatcher(c, key, type))) > -1) {
        const locatedComponent = children.splice(componentPos, 1)[0];
        const traversed = typeof traverseChildren === "function" ? traverseChildren(locatedComponent) : locatedComponent;
        if (traversed) {
            located.push(traversed);
        }
    }
    return located;
};
const isGenerated = (c) => !!c && (typeof c === "object" || typeof c === "function") && "_groupGenerated" in c && Boolean(c._groupGenerated);

const getDefaultFactory = (rootName) => (key) => proxyComponentFactory(`${rootName}.${key}`);
const defaultTraverseChildren = (c) => (c && typeof c === "object" && "props" in c && c.props.children) || undefined;
const defaultComponentMatcher = (c, _, t) => !!c && typeof c === "object" && "type" in c && c.type === t;
const parseGroupingSpec = (childrenSpec, proxyComponentFactory) => {
    const clonedSpec = { ...childrenSpec };
    generateNulls(clonedSpec, proxyComponentFactory);
    return clonedSpec;
};
const extractChildren = (children, parsedSpec, config) => {
    const { childrenToArray = Children.toArray, componentMatcher = defaultComponentMatcher, traverseChildren = defaultTraverseChildren, } = config;
    const restChildren = childrenToArray(children);
    const extractedChildren = Object.assign.apply(undefined, [
        {},
        ...typedKeys(parsedSpec).map((k) => ({
            [uncapitalize(k.toString())]: spliceChildrenByType(restChildren, k, parsedSpec[k], componentMatcher, isGenerated(parsedSpec[k]) ? traverseChildren : undefined),
        })),
    ]);
    return { specChildren: extractedChildren, restChildren };
};
/**
 * Gives an ability to pass multiple number of children groups to a component using classic
 * React component inheritance hierarchies instead of attributes. The component can consume
 * groups or grouping components from matching properties.
 * @param Component a component to modify.
 * @param childrenSpec specification object to define list of grouping components. Use Pascal case
 * to defines keys. props will be automatically uncapitalized to camelCase. Component `GroupName` can
 * be registered as `{ GroupName }` (or `{ GroupName: GroupName }`) and will be available as `groupName`
 * property. If property value is set to `null` like `{ Group2: null }` a proxy component is generated
 * and `group2` property will contain the children of the proxy component, not the proxy itself.
 * @param config an optional config to overwrite some behavior.
 * @returns Component with set of grouping components defined as static properties to use
 * like `<Component.GroupName ... >grouped children</Component.GroupName>
 */
const withGroupedChildren = (config) => (Component) => {
    const componentName = Component.displayName || Component.name;
    const { getComponentName, childrenSpec } = config;
    const parsedSpec = parseGroupingSpec(childrenSpec, config.proxyComponentFactory || getDefaultFactory(componentName));
    const hoc = (props) => {
        const { specChildren, restChildren } = extractChildren(props.children, parsedSpec, config);
        return createElement(Component, {
            ...props,
            ...specChildren,
        }, restChildren);
    };
    hoc.displayName =
        (typeof getComponentName === "function" && getComponentName()) || `WithGroupedChildren(${componentName})`;
    return Object.assign(hoc, parsedSpec);
};
//Assertions:
//All should be functions, more info here: https://github.com/microsoft/TypeScript/issues/34523
function markAsGenerated(componentType) {
    Object.assign(componentType, { _groupGenerated: true });
}
function generateNulls(childrenSpec, factory) {
    Object.assign(childrenSpec, Object.fromEntries(typedKeys(childrenSpec)
        .filter(isNullValueKey(childrenSpec))
        .map((k) => {
        const producedComponent = factory(k.toString());
        markAsGenerated(producedComponent);
        return [k, producedComponent];
    })));
}

export { extractChildren, parseGroupingSpec, withGroupedChildren };
